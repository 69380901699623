<template>
  <section>
    <b-card :title="title" :sub-title="subtitle">
      <Blending01 
        :semana="semana"
        :receta="receta"
        :id="invocation_id"
        :data="recetaData" 
        :config="recetaConfig"
        :dataDownload="downloadRecetaData" 
        :configDownload="downloadRecetaConfig"
        >
      </Blending01>
    </b-card>
  </section>
</template>

<script>
import {
  BTableSimple,
  BCard,
  BRow,
  BCol,
  BButton,
  BLink,
} from "bootstrap-vue";

import useApiServices from '@/services/useApiServices.js';
import AgGridTable from '@/views/brunacomponents/ag-grid-table/AgGridTable.vue';
import Blending01 from './Blending01.vue';


export default {

  components: {
    BTableSimple,
    BCard,
    AgGridTable,
    BRow,
    BCol,
    BButton,
    BLink,
    Blending01,
},

  data() {
    return {

      optimizationId: null,
      cellValue: null,
      recetaConfig: null,
      recetaData: null,
      downloadRecetaConfig: null,
      downloadRecetaData: null,
      detallesPlanificacionRecipe: null,
      title: this.$route.params.receta,
      receta: '',
      semana: '',
      subtitle: 'A continuación se está mostrando la planificación de la receta, con el dato y la cantidad de este.'
      
    }
  },
  // mounted() {

  //   useApiServices.getDetallesPlanificacionRecipe(this.optimizationId).then((response) => {
  //     this.title = "Receta " + response.data;
  //   })
  //     .catch((error) => {
  //       console.log(error);
  //     });

  // },
  beforeMount() {
    // this.params contains the cell & row information and is made available to this component at creation time
    // see ICellRendererParams below for more details
    this.cellValue = this.getValueToDisplay(this.params);

    this.invocation_id = this.$route.query.id;
    this.receta = this.$route.params.receta;
    this.semana = this.$route.params.semana;



     console.log('Params', this.$route.params);
     console.log('Query', this.$route.query);

    this.recetaData = useApiServices.recetaData + "/" + this.semana + "/" + this.receta;
    this.downloadRecetaData = useApiServices.downloadRecetaData + "/" + this.semana + "/" + this.receta;

  },

  created() {
    this.title = this.optimizacionId;
  },
    methods: {
    // gets called whenever the user gets the cell to refresh
    refresh(params) {
      // set value into cell again
      this.cellValue = this.getValueToDisplay(params);
    },
    getValueToDisplay(params) {
      return params;
      //return params.valueFormatted ? params.valueFormatted : params.value;
    },
  },
};

</script>

