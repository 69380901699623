<template>
  <section>
    <b-row>
      <b-col sm="12">
        <div style="display: flow-root;">
          <b-button @click="exportRecipe()" style="float:right; margin: 0px 0px 15px 0px; border-color: #ff7f50 !important; background-color: #FF7F50 !important;">
            Descargar receta
          </b-button>
        </div>
        <!-- Hiden table for export-->
        <ag-grid-vue
              ref="recipeTable"
              style="width: 1px; height: 1px"
              class="ag-theme-alpine"
              :columnDefs="columnDefsDownload"
              :rowData="rowDataDownload"
              :pinnedTopRowData="pinnedTopRowsDownload"
              @grid-ready="onGridReady"
              :excelStyles="excelStyles"
        ></ag-grid-vue>

        <ag-grid-vue
              style="width: 100%; height: 900px"
              class="ag-theme-alpine"
              :columnDefs="columnDefs"
              :rowData="rowData"
              :pinnedTopRowData="pinnedTopRows"

        ></ag-grid-vue>

        <b-button :to="{ name: 'pf-historico-planificacion-detail', params: { semana: semana, receta: receta }, query: { optimization_id: id } }"
          style="height: 50px; background-color: #053743 !important; float:right; margin-right: 20px;  margin-top: 20px; padding-top: 17px;">
          Volver a Detalle Semanal</b-button>
      </b-col>
    </b-row>
  </section>
</template> 

<script>
import {
  BCard,
  BRow,
  BCol,
  BButton,
  BLink,
  BCardTitle,
  BTableSimple
} from "bootstrap-vue";

import { AgGridVue } from "ag-grid-vue"
import LayoutVerticalVue from "@/@core/layouts/layout-vertical/LayoutVertical.vue";
import LayoutContentRendererDefaultVue from "@/@core/layouts/components/layout-content-renderer/LayoutContentRendererDefault.vue";
import axios from '@axios';
import useApiServices from '@/services/useApiServices.js';
import LogoPf from './logo.js';




export default {

  components: {
    LayoutVerticalVue,
    LayoutContentRendererDefaultVue,
    BCardTitle,
    BCard,
    BRow,
    BCol,
    BButton,
    BLink,
    BTableSimple,
    AgGridVue
  },
  props: {
    data: String,
    config: String,
    semana: String,
    receta: String,
    id: String,
    dataDownload: String,
    configDownload: String
  },

  data() {
    return {
      columnDefs: [],
      rowData: [],
      pinnedTopRows: [],

      columnDefsDownload: [],
      rowDataDownload: [],
      pinnedTopRowsDownload: [],
      
      gridApi: null,
      columnApi: null,
      excelStyles: [],
      spreadsheets: [],
      pflogo: LogoPf
      
    }
  },

  mounted() {  
    this.getDataDownload();
    this.getData();

    
    this.excelStyles = [
          {
              id: "cell",
              alignment: {
                  horizontal: 'Center', vertical: 'Center'
              },
          },
          {
            id: 'header',
            alignment: {
              vertical: 'Center',
            },
            interior: {
                color: '#AAAAAA',
                pattern: 'Solid',
            },
            borders: {
                borderBottom: {
                  lineStyle: 'Continuous',
                  weight: 1,
                },
                borderTop: {
                  lineStyle: 'Continuous',
                  weight: 1,
                },
                borderLeft: {
                  lineStyle: 'Continuous',
                  weight: 1,
                },
                borderRight: {
                  lineStyle: 'Continuous',
                  weight: 1,
                },
              },
          },
          {
              id: "grayBg",
              alignment: {
                  horizontal: 'Center', vertical: 'Center'
              },
              interior: {
                color: '#AAAAAA',
                pattern: 'Solid',
              },
              borders: {
                borderBottom: {
                  lineStyle: 'Continuous',
                  weight: 1,
                },
                borderTop: {
                  lineStyle: 'Continuous',
                  weight: 1,
                },
                borderLeft: {
                  lineStyle: 'Continuous',
                  weight: 1,
                },
                borderRight: {
                  lineStyle: 'Continuous',
                  weight: 1,
                },
              },
          },
          {
              id: "borderBlack",
              borders: {
                borderBottom: {
                  lineStyle: 'Continuous',
                  weight: 1,
                },
                borderTop: {
                  lineStyle: 'Continuous',
                  weight: 1,
                },
                borderLeft: {
                  lineStyle: 'Continuous',
                  weight: 1,
                },
                borderRight: {
                  lineStyle: 'Continuous',
                  weight: 1,
                },
              },
          }
      ];
  },
  methods: {

    getData() {
      axios.request({
      method: "post",
      url: this.data,
      headers: {
        Accept: "application/json",
      },
      })
      .then((response) =>
      {

        this.columnDefs = response.data.columnDefs;
        this.rowData = response.data.rowData;
        this.pinnedTopRows = response.data.pinnedTopRows;

      })
    },

    getDataDownload() {
      axios.request({
      method: "post",
      url: this.dataDownload,
      headers: {
        Accept: "application/json",
      },
      })
      .then((response) =>
      {

        this.columnDefsDownload = response.data.columnDefs;
        this.rowDataDownload = response.data.rowData;
        this.pinnedTopRowsDownload = response.data.pinnedTopRows;

      })
    },

    exportRecipe(){
      this.spreadsheets.push( this.gridApi.getSheetDataForExcel(this.getParams('operador')) );
      this.spreadsheets.push( this.gridApi.getSheetDataForExcel(this.getRecipeAditivos('dosimetria')) );

      
      //this.gridApi.exportDataAsExcel( this.getParams() );
      this.gridApi.exportMultipleSheetsAsExcel({
          data: this.spreadsheets,
          fileName: 'receta_pf.xlsx',
        }       
      );


    },



    getParams( sheetName) {
      return {
        sheetName: sheetName,
        prependContent: [
          [{ data: { value: this.pflogo, type: 'String', } }, { data: { value: 'GERENCIA DE PRODUCCION', type: 'String', } }],
          [{ data: { value: '', type: 'String', } }, { data: { value: 'FORMULA DE PRODUCTO', type: 'String', } }],
          [],
          [{ data: { value: 'FORMULA VS', type: 'String', },  mergeAcross: 2, styleId: 'grayBg' }],
          [],
          [{ data: { value: 'VERSION ', type: 'String', } }, { data: { value: '2.0 FORMULA VS', type: 'String', } }],
          [{ data: { value: 'CLAVE ', type: 'String', } }, { data: { value: 'VS000', type: 'String', } }],
          [{ data: { value: 'DIRIGIDO A ', type: 'String', } }, { data: { value: 'OPERADOR MOLEDORA', type: 'String', } }],
          [{ data: { value: 'EQUIPO ', type: 'String', } }, { data: { value: '2.0 FORMULA VS', type: 'String', } }],
          [{ data: { value: 'BATCH  ', type: 'String', } }, { data: { value: '2000 kgs', type: 'String', } }],
          [{ data: { value: 'COMIENZA A USARSE ', type: 'String', } }, { data: { value: '26-ene-23', type: 'String', } }],
          [],
        ],
        appendContent: [
          [{ data: { value: 'TOTAL ADITIVOS Y CARNICOS ', type: 'String', },  mergeAcross: 1, styleId: 'grayBg' }, { data: { value: '1900', type: 'String', }, styleId: 'grayBg' }],
          [],
          [{ data: { value: 'REPROCESOS ', type: 'String', },  mergeAcross: 1, styleId: 'grayBg' }, { data: { value: 'kg', type: 'String', }, styleId: 'grayBg' }],
          [],
          [{ data: { value: 'TOTAL ', type: 'String', },  mergeAcross: 1, styleId: 'grayBg' }, { data: { value: '1900', type: 'String', }, styleId: 'grayBg' }],
          [],
          [{ data: { value: 'Realizado por: ', type: 'String', }, styleId: 'grayBg' }, { data: { value: 'Revisado por: ', type: 'String', }, styleId: 'grayBg' }, { data: { value: 'Aprobado por: ', type: 'String', }, styleId: 'grayBg' }],
          [{ data: { value: 'Asistencia Técnica y Desarrollo', type: 'String', },styleId: 'borderBlack' }, { data: { value: 'Asistencia Técnica y Desarrollo', type: 'String', }, styleId: 'borderBlack' }, { data: { value: 'Gerencia de Producción', type: 'String', }, styleId: 'borderBlack' }],

        ],

        addImageToCell: (rowIndex, col, value) => {
          if (rowIndex !== 1 || col.getColId() !== 'variable') {
            return;
          }
          return {
            image: {
              id: 'logo',
              base64: value,
              imageType: 'png',
              width: 80,
              height: 50,
              position: {
                colSpan: 2,
              },
            },
          };
        },

      }
    },

    getRecipeAditivos( sheetName) {
      return {
        sheetName: sheetName,
        prependContent: [
          [{ data: { value: this.pflogo, type: 'String', } }, { data: { value: 'GERENCIA DE PRODUCCION', type: 'String', } }],
          [{ data: { value: '', type: 'String', } }, { data: { value: 'FORMULA DE PRODUCTO', type: 'String', } }],
          [],
          [{ data: { value: 'FORMULA VS', type: 'String', },  mergeAcross: 2, styleId: 'grayBg' }],
          [],
          [{ data: { value: 'VERSION ', type: 'String', } }, { data: { value: '2.0 FORMULA VS', type: 'String', } }],
          [{ data: { value: 'CLAVE ', type: 'String', } }, { data: { value: 'VS000', type: 'String', } }],
          [{ data: { value: 'DIRIGIDO A ', type: 'String', } }, { data: { value: 'OPERADOR MICRODOSIMETRIA', type: 'String', } }],
          [{ data: { value: 'EQUIPO ', type: 'String', } }, { data: { value: '2.0 FORMULA VS', type: 'String', } }],
          [{ data: { value: 'BATCH  ', type: 'String', } }, { data: { value: '2000 kgs', type: 'String', } }],
          [{ data: { value: 'COMIENZA A USARSE ', type: 'String', } }, { data: { value: '26-ene-23', type: 'String', } }],
          [],

          [{ data: { value: 'CODIGO ', type: 'String', }, styleId: 'grayBg' }, { data: { value: 'ADITIVO ', type: 'String', }, styleId: 'grayBg' }, { data: { value: 'DOSIS (kg) ', type: 'String', }, styleId: 'grayBg' }],

          [{ data: { value: 'I.01119.000.2', type: 'String'}, }, { data: { value: 'SAL INDUSTRIAL SECA 088 YODADA', type: 'String', }}, { data: { value: '27', type: 'Number', } }],
          [{ data: { value: 'I.01101.000.0', type: 'String'}, }, { data: { value: 'SAL DE CURA PR-05 (Env.25kg)', type: 'String', }}, { data: { value: '3', type: 'Number', } }],
          [{ data: { value: 'I.03271.VIE.1', type: 'String'}, }, { data: { value: 'UNIMIX SALCH.SURENA PF III 10 U36483-52(Env.20kg)', type: 'String', }}, { data: { value: '21', type: 'Number', } }],
          [{ data: { value: 'I.01978.000.1', type: 'String'}, }, { data: { value: 'GERVISOL 1118 (EI-60) (ENV.25 KGS)', type: 'String', }}, { data: { value: '20', type: 'Number', } }],
          [{ data: { value: 'I.0N226.000.0', type: 'String'}, }, { data: { value: 'GALIMAX DIACE K 10 (TOT 1300 KGS)', type: 'String', }}, { data: { value: '32', type: 'Number', } }],
          [{ data: { value: 'I.02788.000.0', type: 'String'}, }, { data: { value: 'ARCON SM (Env.20 kgs)', type: 'String', }}, { data: { value: '20', type: 'Number', } }],
          [{ data: { value: 'I.03026.000.0', type: 'String'}, }, { data: { value: 'PROTEINA TEXTURIZADA DE SOJA F GRUESA Bolsa 15 kg', type: 'String', }}, { data: { value: '20', type: 'Number', } }],
          [{ data: { value: 'I.03341.000.1', type: 'String'}, }, { data: { value: 'COLORANTE NARANJA NFS01 PFCOD.PP23MX970KN010PF', type: 'String', }}, { data: { value: '12', type: 'Number', } }],
          [{ data: { value: 'I.0N210.000.0', type: 'String'}, }, { data: { value: 'VEPRO 75 PSC COD.32078 (Env.25 kg)', type: 'String', }}, { data: { value: '6', type: 'Number', } }],
          [{ data: { value: 'I.0P359.000.0', type: 'String'}, }, { data: { value: 'VISKON 5500 (Carragenina) 20kg', type: 'String', }}, { data: { value: '5', type: 'Number', } }],
          [{ data: { value: 'I.0N163.000.0', type: 'String'}, }, { data: { value: 'SCANGEL SF-85 (20 kgs)', type: 'String', }}, { data: { value: '4', type: 'Number', } }],

          [],
          [],

        ],
        appendContent: [
          [{ data: { value: 'TOTAL ADITIVOS', type: 'String', },  mergeAcross: 1, styleId: 'grayBg' }, { data: { value: '170,0', type: 'String', }, styleId: 'grayBg' }],
          [],
          [{ data: { value: 'Realizado por: ', type: 'String', }, styleId: 'grayBg' }, { data: { value: 'Revisado por: ', type: 'String', }, styleId: 'grayBg' }, { data: { value: 'Aprobado por: ', type: 'String', }, styleId: 'grayBg' }],
          [{ data: { value: 'Subgerencia de Desarrollo', type: 'String', },styleId: 'borderBlack' }, { data: { value: 'Subgerencia de Desarrollo', type: 'String', }, styleId: 'borderBlack' }, { data: { value: 'Gerencia de Producción', type: 'String', }, styleId: 'borderBlack' }],

        ],
        //no mostrare ni columnas ni datos de la tabla recipe
        skipColumnHeaders: true,
        onlySelected: true,
        //
        addImageToCell: (rowIndex, col, value) => {
          if (rowIndex !== 1 || col.getColId() !== 'variable') {
            return;
          }
          return {
            image: {
              id: 'logo',
              base64: value,
              imageType: 'png',
              width: 80,
              height: 50,
              position: {
                colSpan: 2,
              },
            },
          };
        },

      }
    },

    onGridReady(params) {
      this.gridApi = params.api;
      this.gridColumnApi = params.columnApi;

      const createServerSideDatasource = () => {
          return {
              getRows: this.getRows,
          };
      };

      const updateData = () => {
          // create datasource with a reference to the fake server
          var datasource = createServerSideDatasource();
          // register the datasource with the grid
          params.api.setServerSideDatasource(datasource);
      };

      updateData();

      this.$emit('gridReady', this.gridApi, this.gridColumnApi, params);
    },
  },
};

</script>
